window.onscroll = function() {BlockMenu()};
var header = document.getElementById("myHeader");
var sticky = header.offsetTop;

document.addEventListener('DOMContentLoaded', function () {
    var olvBurgerClose = document.getElementById('olvburgerclose');
    var olvBurgerOpen = document.getElementById('olvburgeropen');

    olvBurgerClose.addEventListener('click', function (event) {
        event.preventDefault();
        var mybnt = this;
        OlvCloseMenu(mybnt);
    });

    olvBurgerOpen.addEventListener('click', function (event) {
        event.preventDefault();
        var mybnt = this;
        OlvOpenMenu(mybnt);
    });
});

function OlvOpenMenu(mybnt) {
    var myDiv = document.getElementById('main');
    var myDivMenu = document.getElementById('olv-menu-bloc');
    myDivMenu.classList.remove('close');
    myDivMenu.classList.add('open');
    return this;
}

function OlvCloseMenu(mybnt) {
    var myDiv = document.getElementById('main');
    var myDivMenu = document.getElementById('olv-menu-bloc');
    myDivMenu.classList.remove('open');
    myDivMenu.classList.add('close');
    return this;
}

function BlockMenu() {
  if (window.pageYOffset > sticky) {
    header.classList.add("sticky");
  } else {
    header.classList.remove("sticky");
  }
} 
