document.addEventListener('DOMContentLoaded', function () {
    var mycookie = getCookie('olv-privacy-bar');

    if (mycookie === null || mycookie === undefined) {
        setCookie('olv-privacy-bar', null, { expires: 30, path: '/', secure: true });
        setTimeout(function () {
            removeCookieBtn();
        }, 3000);
    }
    else if ((mycookie !== null || mycookie !== undefined) && (mycookie === 'true' || mycookie === 'false')) {
        setTimeout(function () {
            removeCookieBar();
        }, 3000);
    }

    document.querySelector('.olv-rgpd-reset-cookies').addEventListener('click', function (e) {
        e.preventDefault();
        setCookie('olv-privacy-bar', false, { expires: 30, path: '/', secure: true });
        location.reload();
    });

    document.querySelector('.olv-rgpd-agree-cookies').addEventListener('click', function (e) {
        e.preventDefault();
        setCookie('olv-privacy-bar', true, { expires: 30, path: '/', secure: true });
        removeCookieBar();
    });

    document.querySelector('#olv-rgpd-change').addEventListener('click', function (e) {
        e.preventDefault();
        removeCookieBtn();
    });
});

function getCookie(name) {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim().split('=');
        if (cookie[0] === name) {
            return decodeURIComponent(cookie[1]);
        }
    }
    return null;
}

function setCookie(name, value, options) {
    options = options || {};
    var expires = options.expires;
    if (typeof expires === 'number' && expires) {
        var d = new Date();
        d.setTime(d.getTime() + expires * 24 * 60 * 60 * 1000);
        expires = options.expires = d;
    }
    if (expires && expires.toUTCString) {
        options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    var updatedCookie = name + '=' + value;

    for (var propName in options) {
        updatedCookie += '; ' + propName;
        var propValue = options[propName];
        if (propValue !== true) {
            updatedCookie += '=' + propValue;
        }
    }

    document.cookie = updatedCookie;
}

function removeCookieBar() {
    var mybar = document.querySelector("#olv-rgpd-bar");
    mybar.classList.remove('show');
    document.querySelector("#olv-rgpd-change").classList.add('show');
}

function removeCookieBtn() {
    var mybtn = document.querySelector("#olv-rgpd-change");
    mybtn.classList.remove('show');
    document.querySelector("#olv-rgpd-bar").classList.add('show');
}
